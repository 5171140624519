import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuex from "vuex";
import mapboxgl from "mapbox-gl";


Vue.filter("formatdate", function(value) {
  if (!value) return "";
  return value
    .substring(0, 10)
    .split("-")
    .reverse()
    .join("/");
});


  //----------MAPBOX-----------------

     //mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnZWUiLCJhIjoiY2p5c3FmOWgxMG44ajNsbG0weTdmbHFqMCJ9.xzWqmSJjMqpM86r1Kf1jhg'; //Token ANTIGUO ---no muestra el mapa---
  
    mapboxgl.accessToken = "pk.eyJ1IjoiZGdmbSIsImEiOiJjbDllbjB3M2QwMnB0M3VwM3Y3b3dndmgxIn0.i22M60cUqZpsMSGqhNId2g";  // Token ACTUAL ---funciona para visualizar mapas---
                            

  
 Vue.mixin({
  methods: {
    drowMap(cordy, cordx, esfm) {
      let map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [cordy, cordx],
        zoom: 15
      });

      map.addControl(new mapboxgl.NavigationControl());
      map.doubleClickZoom.disable();
      let popup = new mapboxgl.Popup({ offset: 33 })
      .setText(esfm);
      new mapboxgl.Marker()
        .setLngLat([cordy, cordx])
        .setPopup(popup)
        .addTo(map);
    }
  }
}); 
//--------MAPBOX-----------------



Vue.config.productionTip = false;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    home: router.currentRoute.name == "home" || "s",
  },
  mutations: {
    authSuccess(state, status) {
      state.home = status;
    },
  },
  actions: {},
  getters: {
    isAuth: (state) => !!state.home,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
