<template>
  <div
    data-collapse="medium"
    data-animation="default"
    data-duration="400"
    data-no-scroll="1"
    data-w-id="ea04240a-80e8-9465-28c2-8634238e6514s"
    role="banner"
    class="navigation w-nav"
    style="background: rgba(255, 255, 255, 0.90)"
  >
    <div class="navbar-container">
      <a href="/" aria-current="page" class="brand w-nav-brand w--current">
        <img
          src="../assets/minedu.png"
          loading="lazy"
          alt="logo-minedu"
          class="logo-icons"
          width="360" />
        <div class="logo-text"></div
      ></a>
      <header>
        <div class="menu_bar">
          <a href="#" class="bt-menu"><span class="icon-list2"></span></a>
        </div>
        <nav>
          <ul class="menu_color">
            <li>
              <router-link to="/" @click.native="setRoute()"
                >Inicio</router-link
              >
            </li>
            <li>
              <router-link to="/acerca" @click.native="setRoute()"
                >Acerca de</router-link
              >
            </li>
            <li>
              <router-link to="/institucion" @click.native="setRoute()"
                >Escuelas</router-link
              >
            </li>
            <!-- <li class="submenu">
              <a href="#"
                >Proyectos<span class="caret icon-arrow-down6"></span
              ></a>
              <ul class="children">
                <li>
                  <a href="#">SubElemento #1</a>
                </li>
                <li>
                  <a href="#">SubElemento #2</a>
                </li>
                <li>
                  <a href="#">SubElemento #3</a>
                </li>
              </ul>
            </li> -->
            <li>
              <router-link to="/noticia">Noticias</router-link>
            </li>
            <li>
              <router-link to="/normativa">Normativa</router-link>
            </li>
            <li>
              <router-link to="/contacto">Contacto</router-link>
            </li>
          </ul>
        </nav>
      </header>
      <!-- <a href="/" aria-current="page" class="brand w-nav-brand w--current">
        <img
          src="../assets/dgfm.svg"
          loading="lazy"
          alt="logo-dgfm"
          class="logo-icons"
          width="120"
        />
        <div class="logo-text">{{ menu_color }}</div></a
      >
      <nav role="navigation" class="nav-menu w-nav-menu">
        <div data-hover="1" data-delay="0" class="navbar-dropdown w-dropdown">
          <div class="dropdown-toggle w-dropdown-toggle" :class="menu_color">
            <div class="dropdown-icon w-icon-dropdown-toggle"></div>
            <div>Inicio</div>
          </div>
          <nav class="dropdown-list w-dropdown-list">
            <a
              href="/landing-pages/landing-i"
              class="dropdown-link w-dropdown-link"
              >Landing I</a
            ><a
              href="/landing-pages/landing-ii"
              class="dropdown-link w-dropdown-link"
              >Landing II</a
            ><a
              href="/landing-pages/landing-iii"
              class="dropdown-link w-dropdown-link"
              >Landing III</a
            ><a
              href="/landing-pages/landing-iv"
              class="dropdown-link w-dropdown-link"
              >Landing IV</a
            >
          </nav>
        </div>
        <div data-hover="1" data-delay="0" class="navbar-dropdown w-dropdown">
          <div class="dropdown-toggle white w-dropdown-toggle">
            <div class="dropdown-icon w-icon-dropdown-toggle"></div>
            <div>Acerca de</div>
          </div>
          <nav class="dropdown-list w-dropdown-list">
            <a href="/pricing/pricing-i" class="dropdown-link w-dropdown-link"
              >Pricing I</a
            ><a href="/pricing/pricing-ii" class="dropdown-link w-dropdown-link"
              >Pricing II</a
            ><a
              href="/pricing/pricing-iii"
              class="dropdown-link w-dropdown-link"
              >Pricing III</a
            ><a href="/pricing/pricing-iv" class="dropdown-link w-dropdown-link"
              >Pricing IV</a
            >
          </nav>
        </div>
        <div data-hover="1" data-delay="0" class="navbar-dropdown w-dropdown">
          <div class="dropdown-toggle white w-dropdown-toggle">
            <div class="dropdown-icon w-icon-dropdown-toggle"></div>
            <div>Blog</div>
          </div>
          <nav class="dropdown-list w-dropdown-list">
            <a href="/blog1.html" class="dropdown-link w-dropdown-link"
              >Blog I</a
            ><a href="/blog2.html" class="dropdown-link w-dropdown-link"
              >Blog II</a
            ><a
              href="https://keylab.webflow.io/blog-posts/tips-tools-to-write-a-good-blog-post"
              class="dropdown-link w-dropdown-link"
              >Single Post</a
            >
          </nav>
        </div>
        <div data-hover="1" data-delay="0" class="navbar-dropdown w-dropdown">
          <div class="dropdown-toggle white w-dropdown-toggle">
            <div class="dropdown-icon w-icon-dropdown-toggle"></div>
            <div>Pages</div>
          </div>
          <nav class="dropdown-list w-dropdown-list">
            <a href="/about" class="dropdown-link w-dropdown-link">About</a
            ><a href="/contact" class="dropdown-link w-dropdown-link">Contact</a
            ><a href="/help-center" class="dropdown-link w-dropdown-link"
              >Help Center</a
            ><a
              href="https://keylab.webflow.io/help-articles/our-security-policy"
              class="dropdown-link w-dropdown-link"
              >Help Article</a
            >
          </nav>
        </div>
        <div data-hover="1" data-delay="0" class="navbar-dropdown w-dropdown">
          <div class="dropdown-toggle white w-dropdown-toggle">
            <div class="dropdown-icon w-icon-dropdown-toggle"></div>
            <div>Information</div>
          </div>
          <nav class="dropdown-list w-dropdown-list">
            <a
              href="/information/style-guide"
              class="dropdown-link w-dropdown-link"
              >Style Guide</a
            ><a
              href="/information/license"
              class="dropdown-link w-dropdown-link"
              >License</a
            ><a
              href="/information/changelog"
              class="dropdown-link w-dropdown-link"
              >Changelog</a
            ><a href="/404" class="dropdown-link w-dropdown-link">404 Page</a
            ><a href="/401" class="dropdown-link w-dropdown-link"
              >Protected Page</a
            >
          </nav>
        </div>
        <router-link to="/acerca">Acerca de</router-link>
        <a
          href="/information/license"
          class="dropdown-links white w-button sw-dropdown-link"
          >License</a
        >
        <div class="buttons-container">
          <a
            data-w-id="ea04240a-80e8-9465-28c2-8634238e6562"
            href="#"
            class="primary-small-button white w-button"
            >Get it Now</a
          >
        </div>
      </nav>
      <div class="menu-button w-nav-button">
        <div class="burger-icon">
          <div class="top-burger-line"></div>
          <div class="middle-burger-line"></div>
          <div class="bottom-burger-line"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "topbar",
  data: () => ({
    menu_color: "text-white",
  }),
  mounted() {
    if (
      this.$router.currentRoute.name != null ||
      this.$router.currentRoute.name == "home"
    ) {
      this.menu_color = "text-white";
    } else {
      this.menu_color = "";
    }
  },
  methods: {
    setRoute() {
      if (this.$router.currentRoute.name == "home") {
        this.menu_color = "text-white";
      } else {
        this.menu_color = "";
      }
    },
  },
};
</script>

<style scoped></style>
