import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

let paths = [
  {
    path: "*",
    redirect: { path: "/404" },
  },
  {
    path: "/404",
    name: "not_found",
    component: () => import(`@/views/NotFound.vue`),
  },
  {
    path: "/",
    name: "home",
    component: () => import(`@/views/Home.vue`),
  },
  {
    path: "/acerca",
    name: "about",
    component: () => import(`@/views/About.vue`),
  },
  {
    path: "/institucion",
    name: "institution",
    component: () => import(`@/views/Institution.vue`),
    /* children: [
      {
        path: 'detalle',
        name: 'institucion_detalle',
        component: () => import (`@/views/InstitucionDetail.vue`),
      },
    ] */
  },
  {
    path: "/institucion/detalle",
    name: "institution_detail",
    component: () => import(`@/views/InstitutionDetail.vue`),
  },
  {
    path: "/noticia",
    name: "news",
    component: () => import(`@/views/News.vue`),
  },
  {
    path: "/noticia/detalle",
    name: "news_detail",
    component: () => import(`@/views/NewsDetail.vue`),
  },
  {
    path: "/normativa",
    name: "normative",
    component: () => import(`@/views/Announcement.vue`),
  },
  {
    path: "/contacto",
    name: "contact",
    component: () => import(`@/views/Contact.vue`),
  },
  {
    path: "/normativa1",
    name: "normative1",
    component: () => import(`@/views/Announcement1.vue`),
  },
];
Vue.use(Router);
const router = new Router({
  base: "/",
  mode: "hash",
  linkActiveClass: "active",
  routes: paths,
});

router.afterEach(() => {
  NProgress.done();
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  } else {
    next();
  }
});

export default router;
