<template>
  <div class="footer dark">
    <div class="footer-top">
      <div class="footer-grid">
        <router-link to="/" id="footer_logo" class="brand w-nav-brand"
          ><img
            src="../assets/logo_dgfm.svg"
            loading="lazy"
            alt=""
            class="logo-icons"/>
          <div class="logo-text"></div
        ></router-link>
        <div id="footer_navegation" class="links-container">
          <h5 class="h5">Navegación</h5>
          <router-link to="/" class="footer-link grey">Inicio</router-link>
          <router-link to="/acerca" class="footer-link grey"
            >Acerca de</router-link
          >
          <router-link to="/institucion" class="footer-link grey"
            >Escuelas</router-link
          >
        </div>
        <div id="footer_site" class="links-container">
          <h5 class="h5">Sitios</h5>
          <a
            href="https://www.minedu.gob.bo"
            class="footer-link grey"
            target="_blank"
          >
            Ministerio de Educación
          </a>
          <a
            href="https://www.upedagogica.edu.bo"
            class="footer-link grey"
            target="_blank"
          >
            Universidad Pedagógica
          </a>
          <a
            href="http://unefco.edu.bo"
            class="footer-link grey"
            target="_blank"
          >
            Unidad Especializada de Formación Continua
          </a>
        </div>
        <!--<div id="footer_social" class="social-container">
          <a
            href="https://twitter.com/dgfm"
            target="_blank"
            class="social-link w-inline-block"
          >
            <img src="icons/twitter.svg" loading="lazy" alt="twitter" />
          </a>
          <a
            href="https://facebook.com/dgfm"
            target="_blank"
            class="social-link w-inline-block"
          >
            <img src="icons/facebook.svg" loading="lazy" alt="facebook" />
          </a>
          <a
            href="https://instagram.com/dgfm"
            target="_blank"
            class="social-link w-inline-block"
          >
            <img src="icons/instagram.svg" loading="lazy" alt="instagram" />
          </a>
        </div>-->
      </div>
    </div>
    <div class="footer-bottom grey">
      <div>
        &copy; Derechos Reservados,
        <b>Dirección General de Formación de Maestros</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mfooter",
};
</script>

<style scoped></style>
