<template>
  <div id="app">
    <Topbar></Topbar>
    <!-- <img alt="Vue logo" src="./assets/dgfm.svg" /> -->
    <main id="main">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Topbar from "./components/Topbar";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Topbar,
    Footer,
  },
};
</script>

<style>
#main {
  min-height: calc(100vh - 282px);
}
.router-link-active {
  color: red;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
